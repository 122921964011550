import React, { useEffect, useState } from "react";
import "./App.scss";
import AccordionItem from "./collapsableItem";
import PortfolioData from "./portfolioData";
import References from "./references";
import Init, { preloadImages } from "./helper";

const nathanImage = require("./profilePhoto.png");

function App() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    preloadImages([nathanImage], function () {
      setIsLoaded(true);
      document.getElementsByTagName("BODY")[0].classList.add("loaded");
      setTimeout(() => {
        Init();
      }, 30);
    });
  }, []);

  return [
    <div className="loading">
      <div className="loading__message">Loading...</div>
    </div>,
    <div className="App">
      <div className="headerSpacer" />
      <div className="content">
        <div className="heroContent scroll">
          <div className="heroContent__text">
            <h1 className="dearMark scroll">
              <span>D</span>
              <span>e</span>
              <span>a</span>
              <span>r</span>
              <br />
              <span>M</span>
              <span>a</span>
              <span>r</span>
              <span>k</span>
              <span>,</span>
            </h1>
            <p className="dearMark__subtitle fadeUpScroll scroll">
              (I was going to start off with “Oh Hi Mark” but I’m sure you're very tired of that
              joke.)
            </p>
            <p className="fadeUpScroll scroll">
              Thanks for the opportunity to apply for your web designer role! My name’s Nathan and
              I’m a web designer/developer hybrid with 6 years of experience, based out of Toronto,
              Canada. In that time, I’ve worked at startups, corporate, and medium-sized companies
              designing and building websites, all the while staying busy with freelance.
            </p>
            <p className="fadeUpScroll scroll">
              I'd love the opportunity to strengthen my skills specifically in designing for social
              media, and I’m confident I’d be able to help in this area given my experience in web
              UI design. I’m actively looking to take on more freelance work in order to take the
              jump from a cog-in-the-machine to kickass freelancer in the coming months!
            </p>
          </div>
          <div className="heroContent__image scroll">
            <div className="heroContent__image__inner">
              <div className="heroContent__image__overlay" />
              <img src={nathanImage} alt="Nathan Dallaire" />
            </div>
            {isLoaded && (
              <div className="circleAnimation__animationContainer">
                <div className="circleAnimation" />
              </div>
            )}
            <div className="heroContent__imageCaption">This is the best photo ever taken of me</div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="accordionSection scroll">
          <AccordionItem title="About Me">
            <>
              <p>
                This past year’s been messed up for the world, but it's given me some time to reset
                myself. For the first time in my life I feel more in control of my career and goals.
                In the past year I’ve got more into reading, in which time I’ve been reading your
                blog. More recently, I read your book “Everything is Fucked” and I loved it. I even{" "}
                <a
                  href="https://medium.com/@natedallaire/book-review-everything-is-fucked-a-book-about-hope-343f962aa7f8"
                  target="_blank"
                >
                  wrote a medium article summarizing my favourite points
                </a>
                . I also recently bought your book recommendation “The Denial of Death” and will be
                reading that one next. Thanks for the reco!{" "}
              </p>
              <p>
                When it comes to the technical side of things, I’m proficient in HTML, CSS/SCSS,
                Javascript, Shopify, React and have experience working with Wordpress and other
                content management systems. When it comes to design, I work well with Photoshop,
                Sketch, and Illustrator. In terms of video work, I’m no expert but I’ve used Adobe
                Premier plenty of times in the past.
              </p>
              <p>
                I’m a jack of all trades when it comes to anything web, and if there’s a problem,
                I’ll figure it out someway or another.{" "}
              </p>
              <p>
                Lastly,{" "}
                <a
                  href="https://www.facebook.com/NowThisNews/videos/three-best-friends-go-to-get-mcdonalds-pizza/1374422355981218/"
                  target="_blank"
                >
                  here’s a viral video
                </a>{" "}
                of my friends and I travelling 800km to buy McDonald’s Pizza at the last of the
                remaining locations that sold it. As you can plainly see, this video demonstrates
                that I’m willing to go the distance to become the best person I can be.
              </p>
            </>
          </AccordionItem>
          <AccordionItem title="Portfolio">
            <>
              <p className="portfolioText">
                My portfolio can be found at{" "}
                <a href="https://nathandallaire.com/" target="_blank">
                  nathandallaire.com
                </a>
                .<br />
                To make things easier for you, I've listed out some recent projects below.
              </p>
              {PortfolioData.map((item, i) => {
                return (
                  <a key={i} href={item.link} target="_blank" className="portfolioItem">
                    <div className="portfolioItem__image">
                      <img src={item.image} alt={item.title} />
                    </div>
                    <div className="portfolioItem__text">
                      <h5 className="portfolioItem__title">{item.title}</h5>
                      <div className="portfolioItem__subtitle">{item.subtitle}</div>
                      <div className="portfolioItem__platform">Technology: {item.platform}</div>
                    </div>
                  </a>
                );
              })}
            </>
          </AccordionItem>
          <AccordionItem title="Pricing and Fee Structure">
            <p>
              My rate is <strong>$100/hour CAD</strong> ($75/hour USD) for both design and
              development. I also do project rates but that’s on a per-project basis and would need
              to hear what you need exactly to give you an accurate quote.
            </p>
          </AccordionItem>
          <AccordionItem title="References">
            <div className="references">
              {References.map((item, i) => {
                return (
                  <div key={i} className="referenceItem">
                    <div className="referenceItem__image">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="referenceItem__text">
                      <h5 className="referenceItem__name">{item.name}</h5>
                      <div className="referenceItem__title">{item.title}</div>
                      <a href={`mailto:${item.email}`} className="referenceItem__email">
                        {item.email}
                      </a>
                      <div className="referenceItem__phoneNumber">{item.phoneNumber}</div>
                      <a href={item.linkedIn} target="_blank" className="referenceItem__linkedIn">
                        <img src={require("./images/linkedin.svg")} alt="LinkedIn" /> LinkedIn
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </AccordionItem>
        </div>
      </div>
      <div className="closingRemarks">
        <div className="content">
          <p className="scroll fadeUpScroll">
            I don’t miss deadlines and I work damn hard to get projects done correctly. My ability
            to speak to both design and development has helped tremendously in my career so far, and
            I have no doubt that I'd be a perfect fit for this role because of this. I’d love the
            opportunity to work with you on anything web-related.{" "}
          </p>
          <p className="scroll fadeUpScroll">
            I’m positive we’d not only build a better web presence, but a great working
            relationship.
          </p>
          <p className="scroll fadeUpScroll">Cheers,</p>
          <img
            className="closingRemarks__sig scroll fadeUpScroll"
            src={require("./images/sig--white.png")}
            alt="Nathan Dallaire"
          />
          <p className="closingRemarks__name scroll fadeUpScroll">Nathan Dallaire</p>
          <div className="closingRemarks__emailLinkedIn scroll fadeUpScroll">
            <a className="closingRemarks__email" href="mailto:nathan@dallaire.io">
              nathan@dallaire.io
            </a>
            <div className="closingRemarks__linkedInContainer">
              <a href="https://www.linkedin.com/in/nathandallaire/" target="_blank">
                <img src={require("./images/linkedin.svg")} alt="LinkedIn" />
                nathandallaire
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>,
  ];
}

export default App;
