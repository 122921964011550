export default [
  {
    name: "Jeffery Fulgar",
    title: "Previous Manager at Current Employer",
    email: "jfulgardesign@gmail.com",
    phoneNumber: "416-605-6851",
    linkedIn: "https://www.linkedin.com/in/jfulgar/",
    image: require("./images/ace.jpeg"),
  },
  {
    name: "Dan Demsky",
    title: "Founder of Unbound Merino",
    email: "dan@unboundmerino.com",
    phoneNumber: "416-910-6385",
    linkedIn: "https://www.linkedin.com/in/dandemsky/",
    image: require("./images/dan.jpeg"),
  },
  {
    name: "Amanda Ashford",
    title: "Freelance client for Länk",
    email: "amanda.leigh.ashford@gmail.com",
    phoneNumber: "416-556-2634",
    linkedIn: "https://www.linkedin.com/in/amanda-ashford/",
    image: require("./images/amanda.jpeg"),
  },
  {
    name: "Charan Guru",
    title: "Manager at my previous job (Demac)",
    email: "charan.guru90@gmail.com",
    phoneNumber: "647-303-4279",
    linkedIn: "https://www.linkedin.com/in/charan-guru-938b9610/",
    image: require("./images/charan.jpeg"),
  },
  {
    name: "Marc Tavas",
    title: "Manager at Nuscreen (my first startup job)",
    email: "marc.tavas@gmail.com",
    phoneNumber: "647-802-5729",
    linkedIn: "https://www.linkedin.com/in/marctavas/",
    image: require("./images/marc.jpeg"),
  },
];
