export default [
  {
    title: "Unbound Merino",
    subtitle: "Long-Term Freelance Client",
    platform: "Shopify",
    link: "https://unboundmerino.com/",
    image: require("./images/client-unboundmerino.png"),
  },
  {
    title: "Länk",
    subtitle: "Freelance Client (in progress)",
    platform: "React",
    link: "https://lank-staging.netlify.com/",
    image: require("./images/client-lank.png"),
  },
  {
    title: "Stack",
    subtitle: "My Current Employer",
    platform: "React, Wordpress",
    link: "https://www.getstack.ca/",
    image: require("./images/client-stack.png"),
  },
  {
    title: "Connect",
    subtitle: "Current project at work (In design phase right now)",
    platform: "React, Prismic",
    link: "https://nathandallaire.com/work/connect",
    image: require("./images/client-connect.png"),
  },
];
