import React, { useState, useRef, useEffect } from "react";

export default function CollapsableComponent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const childRef = useRef(null);

  function toggleAccordion(isOpen) {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    setTimeout(() => {
      if (childRef.current) {
        const height = childRef.current.offsetHeight + 40;
        setHeight(height);
      }
    }, 30);
  }, []);

  const title = (
    <h5
      onClick={() => {
        toggleAccordion(isOpen);
      }}
      className="accordian__title"
    >
      {props.title}
    </h5>
  );

  return (
    <div className={`accordian ${isOpen && "open"}`}>
      {!isOpen ? <span className="accordion__plus" /> : <span className="accordion__minus" />}
      {title}
      <div className="accordian__content" style={{ height: isOpen ? height : 0 }}>
        <div ref={childRef}>{props.children}</div>
      </div>
    </div>
  );
}
