function isScrolledIntoView(el) {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  // Only completely visible elements return true:
  //var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  const isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  return isVisible;
}

export default function init() {
  const elements = document.getElementsByClassName("scroll");

  function showElements() {
    for (let i = 0; i < elements.length; i++) {
      const el = elements[i];

      if (isScrolledIntoView(el)) {
        el.classList.add("visible");
      }
    }
  }

  window.addEventListener("scroll", function () {
    showElements();
  });

  showElements();
}

export function preloadImages(imageSources, callback) {
  var images = [];

  var tryCallback = function () {
    var allImagesLoaded = (function () {
      for (var i = images.length; i--; ) {
        if (!images[i].isLoaded) {
          return false;
        }
      }
      return true;
    })();

    if (allImagesLoaded && callback) {
      callback();
    }
  };

  for (var i = imageSources.length; i--; ) {
    var imageSrc = imageSources[i];
    var image = document.createElement("img");
    images.push(image);
    image.onload = function () {
      this.isLoaded = true;
      tryCallback();
    };
    image.src = imageSrc;
  }
}
